import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Authorize from './Login';

ReactDOM.render(
  <React.StrictMode>
        <Authorize />
  </React.StrictMode>,
  document.getElementById('root')
);

